var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var U2,Gpa,V2,W2,Hpa,Ipa,X2,Y2,Jpa,Kpa,Lpa,Mpa,c3,Opa,Ppa,Qpa,Rpa,f3,g3,h3,i3,k3,Tpa,l3,m3,Upa,Z2,a3;U2=function(a,b){return $CLJS.oe(a)?$CLJS.M.h(a,b):a};
Gpa=function(){return function(){function a(g,l,n){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xk.j?$CLJS.Xk.j(g,l,n):$CLJS.Xk.call(null,g,l,n),$CLJS.nn.j?$CLJS.nn.j(g,l,n):$CLJS.nn.call(null,g,l,n)],null)}function b(g,l){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xk.h?$CLJS.Xk.h(g,l):$CLJS.Xk.call(null,g,l),$CLJS.nn.h?$CLJS.nn.h(g,l):$CLJS.nn.call(null,g,l)],null)}function c(g){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xk.g?$CLJS.Xk.g(g):$CLJS.Xk.call(null,g),$CLJS.nn.g?$CLJS.nn.g(g):$CLJS.nn.call(null,
g)],null)}function d(){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xk.A?$CLJS.Xk.A():$CLJS.Xk.call(null),$CLJS.nn.A?$CLJS.nn.A():$CLJS.nn.call(null)],null)}var e=null,f=function(){function g(n,q,u,v){var x=null;if(3<arguments.length){x=0;for(var y=Array(arguments.length-3);x<y.length;)y[x]=arguments[x+3],++x;x=new $CLJS.z(y,0,null)}return l.call(this,n,q,u,x)}function l(n,q,u,v){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.R.R($CLJS.Xk,n,q,u,v),$CLJS.R.R($CLJS.nn,n,q,u,v)],null)}g.v=3;g.B=function(n){var q=
$CLJS.C(n);n=$CLJS.D(n);var u=$CLJS.C(n);n=$CLJS.D(n);var v=$CLJS.C(n);n=$CLJS.zd(n);return l(q,u,v,n)};g.l=l;return g}();e=function(g,l,n,q){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,g);case 2:return b.call(this,g,l);case 3:return a.call(this,g,l,n);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.z(v,0,null)}return f.l(g,l,n,u)}throw Error("Invalid arity: "+arguments.length);};e.v=
3;e.B=f.B;e.A=d;e.g=c;e.h=b;e.j=a;e.l=f.l;return e}()};V2=function(a,b){return $CLJS.le(a)||$CLJS.me(a)?$CLJS.M.h(a,b):$CLJS.ne(a)?$CLJS.M.h($CLJS.Mg(a),b):null};W2=function(a,b){var c=$CLJS.A(b);for(a=$CLJS.A($CLJS.kg(a,b));;)if(a)c=$CLJS.D(c),a=$CLJS.D(a);else return c};Hpa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return null==e?d:$CLJS.U.j(d,f,e)},null==b?a:$CLJS.U.j(a,$CLJS.MP,b),$CLJS.vu(2,2,c))};
Ipa=function(){var a=$CLJS.Rp.h($CLJS.PZ,null),b=$CLJS.oy(a,$CLJS.Oaa,function(c){return $CLJS.Ro(c,$CLJS.Lg)});return function(){function c(f,g,l){return(g=$CLJS.A(b.j?b.j(f,g,l):b.call(null,f,g,l)))?new $CLJS.k(null,3,[$CLJS.dm,a,$CLJS.sl,f,$CLJS.du,g],null):null}function d(f){return e.j(f,$CLJS.Lg,$CLJS.Lg)}var e=null;e=function(f,g,l){switch(arguments.length){case 1:return d.call(this,f);case 3:return c.call(this,f,g,l)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;return e}()};
X2=function(a){return Ipa()(a,$CLJS.Lg,$CLJS.Lg)};Y2=function(a,b,c,d){d=$CLJS.m(d)?d:$CLJS.Yr($CLJS.dm.g(a));if($CLJS.m(b)){var e=U2($CLJS.Xu.g(b),c);$CLJS.m(e)?(e=$CLJS.bs(e,d),a=e.h?e.h(a,d):e.call(null,a,d)):a=null;return $CLJS.m(a)?a:U2($CLJS.ml.g(b),c)}return null};
Jpa=function(a,b){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.dm),d=$CLJS.M.h(a,$CLJS.Xk);b=$CLJS.Qf(b);var e=$CLJS.M.j(b,$CLJS.du,$CLJS.oba),f=$CLJS.M.j(b,$CLJS.jba,!0),g=$CLJS.M.h(b,$CLJS.nv),l=$CLJS.M.j(b,$CLJS.lv,$CLJS.jv),n=Y2(a,$CLJS.ns.g(c),g,b);if($CLJS.m(n))return n;n=Y2(a,$CLJS.su.g(c),g,b);if($CLJS.m(n))return n;n=Y2(a,e.g?e.g(d):e.call(null,d),g,b);if($CLJS.m(n))return n;n=Y2(a,function(){var q=$CLJS.zp.g(c);return e.g?e.g(q):e.call(null,q)}(),g,b);if($CLJS.m(n))return n;n=Y2(a,$CLJS.ns.g(c),
l,b);if($CLJS.m(n))return n;n=Y2(a,$CLJS.su.g(c),l,b);if($CLJS.m(n))return n;d=Y2(a,e.g?e.g(d):e.call(null,d),l,b);if($CLJS.m(d))return d;d=Y2(a,function(){var q=$CLJS.zp.g(c);return e.g?e.g(q):e.call(null,q)}(),l,b);if($CLJS.m(d))return d;g=$CLJS.m(f)?Y2(a,e.g?e.g($CLJS.hv):e.call(null,$CLJS.hv),g,b):f;return $CLJS.m(g)?g:$CLJS.m(f)?Y2(a,e.g?e.g($CLJS.hv):e.call(null,$CLJS.hv),l,b):f};
Kpa=function(a,b,c){a=$CLJS.T;var d=$CLJS.oh.h;var e=$CLJS.nn.g(b);var f=$CLJS.Qf(b);var g=$CLJS.M.h(f,$CLJS.dm),l=$CLJS.Qf(c);f=$CLJS.M.h(l,$CLJS.nv);l=$CLJS.M.j(l,$CLJS.lv,$CLJS.jv);g=$CLJS.ns.g(g);f=U2($CLJS.kv.g(g),f);f=$CLJS.m(f)?f:U2($CLJS.kv.g(g),l);d=d.call($CLJS.oh,e,f);return new $CLJS.S(null,2,5,a,[d,Jpa(b,c)],null)};
Lpa=function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.sl);a=$CLJS.M.h(b,$CLJS.du);var d=$CLJS.Qf(null),e=$CLJS.M.j(d,$CLJS.kba,$CLJS.yk),f=$CLJS.M.j(d,$CLJS.mba,Kpa);return $CLJS.m(a)?$CLJS.Sb(function(g,l){var n=f.j?f.j(b,l,d):f.call(null,b,l,d),q=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);l=$CLJS.U.j(l,$CLJS.yk,n);l=e.g?e.g(l):e.call(null,l);return Z2(g,c,q,l)},null,a):null};Mpa=function(a){return $CLJS.qg.h($CLJS.uZ(a),$CLJS.vZ(a))};
$CLJS.$2=function(a,b){return $CLJS.Pu($CLJS.Z_(a)?a:new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sl,new $CLJS.k(null,2,[$CLJS.HG,$CLJS.p.g($CLJS.nL()),$CLJS.lk,$CLJS.BL(a)],null),a],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.LL],null),b)};$CLJS.b3=function(){return $CLJS.Mm.h(a3,$CLJS.k_($CLJS.G([$CLJS.l_,$CLJS.jG,$CLJS.m_,function(a,b){return a3.g([$CLJS.p.g(a),"_",$CLJS.p.g(b)].join(""))}])))};
c3=function(a,b,c){var d=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Zt,null,$CLJS.rn,null],null),null),e=$CLJS.m(d.g?d.g(b):d.call(null,b))?$CLJS.Lu(2,c):$CLJS.Lu(1,c);b=$CLJS.m(d.g?d.g(b):d.call(null,b))?W2(2,c):W2(1,c);var f=$CLJS.J(b,0,null);return $CLJS.A(e)?$CLJS.o_.j(a,e,function(g){var l=$CLJS.U.j(g,f,null);return $CLJS.qe(g)?$CLJS.oh.j($CLJS.Lg,$CLJS.Pm.g($CLJS.Cb),l):$CLJS.oe(g)?$CLJS.$F($CLJS.Hb,l):l}):$CLJS.Em.h(a,f)};
Opa=function(a){return $CLJS.Sb(function(b,c){var d=$CLJS.J(c,0,null);$CLJS.J(c,1,null);return c3(b,Npa,d)},a,Mpa(a))};
Ppa=function(a){for(var b=0;;){var c=$CLJS.Td($CLJS.gZ.g(a),b);a:{var d=c;for(var e=$CLJS.Lg;;){var f=$CLJS.C($CLJS.rg.h(Gpa(),$CLJS.ug($CLJS.Mm.j(d3,$CLJS.C,$CLJS.nn),$CLJS.du.g(X2(d)))));if($CLJS.m(f)){var g=f;f=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);var l=c3(d,f,g),n=$CLJS.XF($CLJS.XD);if($CLJS.m($CLJS.dG("metabase.lib.convert",n))){var q=$CLJS.NG.l($CLJS.G([$CLJS.SE,$CLJS.aj.l($CLJS.G([g]))])),u=$CLJS.NG,v=u.l,x=$CLJS.aj,y=x.l;var B=f;B=$CLJS.m(B)?B:Lpa(X2(d));$CLJS.eG("metabase.lib.convert",n,
$CLJS.X_("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([q,v.call(u,$CLJS.G([$CLJS.SE,y.call(x,$CLJS.G([B]))])),$CLJS.NG.l($CLJS.G([$CLJS.HE,$CLJS.Y_($CLJS.C($CLJS.Az(d,l)))]))])),null)}if($CLJS.F.h(l,d))break a;d=l;e=$CLJS.kf.h(e,new $CLJS.S(null,2,5,$CLJS.T,[f,g],null))}else break a}}d=Opa(d);if($CLJS.F.h(c,d)){if($CLJS.F.h(b,$CLJS.E($CLJS.gZ.g(a))-1))return a;b+=1}else a=$CLJS.Rm.R(a,$CLJS.gZ,$CLJS.U,b,d)}};
Qpa=function(a){var b=$CLJS.b3();return $CLJS.Qm.h(function(c){return $CLJS.F.h($CLJS.vP.g(c),"__join")?$CLJS.Rm.j(c,$CLJS.vP,b):c},a)};Rpa=function(a){return"string"===typeof $CLJS.YP.g(a)?$CLJS.Em.h($CLJS.U.j(a,$CLJS.wZ,$CLJS.e0($CLJS.YP.g(a))),$CLJS.YP):a};f3=function(a,b){var c=$CLJS.M.j(a,b,e3);if($CLJS.F.h(c,e3))throw $CLJS.zj(["Unable to find ",$CLJS.aj.l($CLJS.G([b]))," in map."].join(""),new $CLJS.k(null,2,[Spa,a,$CLJS.$u,b],null));return c};
g3=function(){return $CLJS.Pm.g(function(a){var b=$CLJS.J(a,0,null);$CLJS.J(a,1,null);return $CLJS.xf(b)&&$CLJS.F.h($CLJS.qf(b),"lib")})};h3=function(a){return $CLJS.oh.j($CLJS.P,g3(),a)};i3=function(a){return $CLJS.Rf($CLJS.oh.j($CLJS.P,$CLJS.Mm.h(g3(),$CLJS.Pm.g(function(b){var c=$CLJS.J(b,0,null);$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.lk)})),a))};
k3=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d=$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.g($CLJS.j3),c);a=$CLJS.F.h(a,$CLJS.YI)&&$CLJS.D(c)?$CLJS.kf.h($CLJS.ie(d),new $CLJS.k(null,1,[$CLJS.Pj,$CLJS.he(d)],null)):d;b=$CLJS.Rf(i3(b));return $CLJS.m(b)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nO,a,b],null):a};Tpa=function(a){return $CLJS.oh.j($CLJS.Lg,$CLJS.Mm.h($CLJS.rg.g(function(b){return $CLJS.bG(b,$CLJS.SG)}),$CLJS.rg.g($CLJS.j3)),$CLJS.c0.g(a))};
l3=function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.gZ);a=$CLJS.C($CLJS.Sb(function(b,c){var d=$CLJS.J(b,0,null),e=$CLJS.J(b,1,null);b=$CLJS.T;var f=$CLJS.j3.g(c);d=$CLJS.m(d)?$CLJS.U.j(f,$CLJS.kR,d):f;d=$CLJS.m(e)?$CLJS.U.j(d,$CLJS.NP,Tpa(e)):d;return new $CLJS.S(null,2,5,b,[d,$CLJS.nZ.g(c)],null)},null,a));return $CLJS.m($CLJS.TQ.g(a))?$CLJS.PN(a,new $CLJS.k(null,1,[$CLJS.TQ,$CLJS.uO],null)):a};
m3=function(a,b,c){var d=$CLJS.F.h($CLJS.E($CLJS.M.h(a,b)),1)?$CLJS.p_.j(a,b,$CLJS.Mm.h($CLJS.j3,$CLJS.C)):a;a=1<$CLJS.E($CLJS.M.h(a,b))?$CLJS.p_.j(d,b,function(e){return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Lj],null),$CLJS.rg.g($CLJS.j3),e)}):d;return $CLJS.PN(a,$CLJS.Pf([b,c]))};Upa=function(a){var b=$CLJS.wZ.g(a);return $CLJS.m(b)?$CLJS.U.j($CLJS.Em.h(a,$CLJS.wZ),$CLJS.YP,["card__",$CLJS.p.g(b)].join("")):a};
Z2=function Z2(a,b,c,d){var f=$CLJS.A(c);c=$CLJS.C(f);var g=$CLJS.D(f),l=V2(b,c);f=$CLJS.m(a)?a:$CLJS.ne(b)?$CLJS.Lg:$CLJS.pe(b)?$CLJS.P:$CLJS.ae(b);return $CLJS.m($CLJS.m(c)?$CLJS.iv.g($CLJS.fe(f)):c)?a:$CLJS.m(c)?(b=V2(f,c),d=Z2.D?Z2.D(b,l,g,d):Z2.call(null,b,l,g,d),$CLJS.Be(c)&&$CLJS.ne(f)&&c>$CLJS.E(f)&&(b=$CLJS.ng(c-$CLJS.E(f),null),b=$CLJS.qg.h(f,b),f=null==f||$CLJS.we(f)?b:$CLJS.oh.h($CLJS.ae(f),b)),null==f||$CLJS.me(f)?$CLJS.U.j(f,c,d):$CLJS.le(f)?$CLJS.kf.h(f,d):$CLJS.R.h($CLJS.X,$CLJS.U.j($CLJS.Mg(f),
c,d))):$CLJS.oe(a)?(c=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lba],null),Z2.D?Z2.D(f,b,c,d):Z2.call(null,f,b,c,d)):$CLJS.m($CLJS.iv.g($CLJS.fe(f)))?$CLJS.kf.h(f,d):$CLJS.qe($CLJS.Rf(f))?f:$CLJS.K(new $CLJS.S(null,1,5,$CLJS.T,[d],null),new $CLJS.k(null,1,[$CLJS.iv,!0],null))};a3=function a3(a){switch(arguments.length){case 1:return a3.g(arguments[0]);case 2:return a3.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
a3.g=function(a){return a3.h(a,60)};a3.h=function(a,b){if((new TextEncoder).encode(a).length<=b)return a;var c;a:for(c=($CLJS.jW.str(a,0)>>>0).toString(16);;)if(8>$CLJS.E(c))c=["0",$CLJS.p.g(c)].join("");else break a;b=new Uint8Array(b-9);b=(new TextEncoder).encodeInto(a,b);return[a.substring(0,b.read),"_",$CLJS.p.g(c)].join("")};a3.v=2;
var e3=new $CLJS.N("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550),Npa=new $CLJS.N("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475),n3=new $CLJS.N("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672),o3=new $CLJS.N("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782),Spa=new $CLJS.N(null,"m","m",1632677161),p3=new $CLJS.N("metabase.lib.convert","aggregation",
"metabase.lib.convert/aggregation",108290790);var q3,r3,d3,Vpa,Wpa,Xpa,Ypa,Zpa,$pa,aqa,bqa,cqa,dqa;q3=$CLJS.P;r3=$CLJS.P;d3=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.MZ,null,$CLJS.wO,null,$CLJS.UO,null,$CLJS.OQ,null,$CLJS.FP,null,$CLJS.YR,null,$CLJS.MP,null],null),null);Vpa=$CLJS.cj.g($CLJS.P);Wpa=$CLJS.cj.g($CLJS.P);Xpa=$CLJS.cj.g($CLJS.P);Ypa=$CLJS.cj.g($CLJS.P);Zpa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.CH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
$CLJS.s3=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3epMBQL"),$CLJS.BH,$CLJS.Pj,Zpa,Vpa,Wpa,Xpa,Ypa);$CLJS.s3.o(null,$CLJS.Pj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){var b=$CLJS.he(a);var c=$CLJS.oe(b);a=c?$CLJS.ie(a):a;var d=$CLJS.A(a);a=$CLJS.C(d);d=$CLJS.D(d);b=$CLJS.eW($CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[a,c?b:$CLJS.P],null),$CLJS.rg.g($CLJS.s3),d))}else b=a;return b});$CLJS.s3.o(null,$CLJS.LZ,function(a){return a});
$CLJS.s3.o(null,$CLJS.BZ,function(a){var b=$CLJS.s3.g($CLJS.MP.g(a)),c=$CLJS.Rf($CLJS.Qm.h(function(g){var l=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.$2($CLJS.s3.g(g),l)},$CLJS.OQ.g(a))),d=r3;r3=$CLJS.oh.j($CLJS.P,$CLJS.Io(function(g,l){$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);l=$CLJS.Qf(l);l=$CLJS.M.h(l,$CLJS.HG);return new $CLJS.S(null,2,5,$CLJS.T,[g,l],null)}),b);try{var e=Hpa(Rpa(a),b,$CLJS.G([$CLJS.OQ,c])),f=$CLJS.Sb(function(g,l){return $CLJS.Gb($CLJS.M.h(g,l))?g:$CLJS.Rm.j(g,l,$CLJS.s3)},
e,$CLJS.Fm.l(d3,$CLJS.MP,$CLJS.G([$CLJS.OQ])));return $CLJS.m($CLJS.UO.g(f))?$CLJS.Rm.j(f,$CLJS.UO,Qpa):f}finally{r3=d}});$CLJS.s3.o(null,$CLJS.bZ,function(a){a=$CLJS.Rm.j($CLJS.Rm.j(a,$CLJS.jZ,$CLJS.s3),$CLJS.gZ,$CLJS.s3);var b=$CLJS.m($CLJS.wO.g(a))?$CLJS.Rm.j(a,$CLJS.wO,function(c){return $CLJS.xe(c)?$CLJS.Qm.h($CLJS.s3,c):$CLJS.Oi.g(c)}):a;return $CLJS.Gb($CLJS.vP.g(a))?$CLJS.U.j(b,$CLJS.vP,"__join"):b});$CLJS.s3.o(null,$CLJS.wG,function(a){return $CLJS.Qm.h($CLJS.s3,a)});
$CLJS.s3.o(null,$CLJS.yG,function(a){return $CLJS.m($CLJS.Xk.g(a))?Ppa($CLJS.Rm.j($CLJS.d0(a),$CLJS.gZ,function(b){return $CLJS.Qm.h($CLJS.s3,b)})):$CLJS.Ku(a,$CLJS.s3)});$CLJS.s3.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[a,b],null):new $CLJS.S(null,2,5,$CLJS.T,[b,a],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.eW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,a,b],null))});
$CLJS.s3.o(null,$CLJS.sl,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.PN(a,new $CLJS.k(null,3,[$CLJS.sJ,$CLJS.CG,$CLJS.BP,$CLJS.Ll,$CLJS.fS,$CLJS.uL],null));var c=$CLJS.U.j;var d=$CLJS.lk.g(a);$CLJS.m(d)||(d=$CLJS.CG.g(a),d=$CLJS.m(d)?d:$CLJS.BL(b));a=c.call($CLJS.U,a,$CLJS.lk,d);return $CLJS.eW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sl,a,b],null))});
$CLJS.s3.o(null,$CLJS.YI,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.Pj.g(c);b=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.YI,$CLJS.Em.h(c,$CLJS.Pj),$CLJS.Qm.h($CLJS.s3,b)],null);b=$CLJS.eW(b);return null!=a?$CLJS.kf.h(b,$CLJS.s3.g(a)):b});$CLJS.s3.o(null,$CLJS.zG,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.eW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,c],null))});
$CLJS.s3.o(null,$CLJS.MP,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.eW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,f3(r3,c)],null))});$CLJS.s3.o(null,$CLJS.nO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=$CLJS.s3.g(b);var c=$CLJS.A(b);b=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);d=$CLJS.D(d);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.gn.l($CLJS.G([c,a]))],null),d)});$pa=$CLJS.cj.g($CLJS.P);aqa=$CLJS.cj.g($CLJS.P);bqa=$CLJS.cj.g($CLJS.P);
cqa=$CLJS.cj.g($CLJS.P);dqa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.CH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));$CLJS.j3=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.BH,$CLJS.Pj,dqa,$pa,aqa,bqa,cqa);
$CLJS.j3.o(null,$CLJS.Pj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){a=$CLJS.A(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);if($CLJS.oe(a)){c=$CLJS.oh.h;b=new $CLJS.S(null,1,5,$CLJS.T,[b],null);var e=$CLJS.qg.h;d=$CLJS.rg.h($CLJS.j3,d);a=i3(a);a=c.call($CLJS.oh,b,e.call($CLJS.qg,d,$CLJS.m(a)?new $CLJS.S(null,1,5,$CLJS.T,[a],null):null))}else a=$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[b],null),$CLJS.rg.h($CLJS.j3,$CLJS.nf(a,d)))}else a=$CLJS.oe(a)?$CLJS.Ku(h3(a),$CLJS.j3):
a;return a});for(var t3=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[p3,o3],null)),u3=null,v3=0,w3=0;;)if(w3<v3){var eqa=u3.$(null,w3);$CLJS.lV(eqa,n3);w3+=1}else{var x3=$CLJS.A(t3);if(x3){var y3=x3;if($CLJS.re(y3)){var z3=$CLJS.$c(y3),fqa=$CLJS.ad(y3),gqa=z3,hqa=$CLJS.E(z3);t3=fqa;u3=gqa;v3=hqa}else{var iqa=$CLJS.C(y3);$CLJS.lV(iqa,n3);t3=$CLJS.D(y3);u3=null;v3=0}w3=0}else break}
for(var A3=$CLJS.A(new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.kD,$CLJS.BJ,$CLJS.dK,$CLJS.LI,$CLJS.Zl,$CLJS.tK,$CLJS.qk,$CLJS.lJ,$CLJS.XJ,$CLJS.yK,$CLJS.rK,$CLJS.MK],null)),B3=null,C3=0,D3=0;;)if(D3<C3){var jqa=B3.$(null,D3);$CLJS.lV(jqa,p3);D3+=1}else{var E3=$CLJS.A(A3);if(E3){var F3=E3;if($CLJS.re(F3)){var G3=$CLJS.$c(F3),kqa=$CLJS.ad(F3),lqa=G3,mqa=$CLJS.E(G3);A3=kqa;B3=lqa;C3=mqa}else{var nqa=$CLJS.C(F3);$CLJS.lV(nqa,p3);A3=$CLJS.D(F3);B3=null;C3=0}D3=0}else break}
for(var H3=$CLJS.A($CLJS.Hg([$CLJS.mm,$CLJS.xu,$CLJS.nm,$CLJS.OK,$CLJS.YI,$CLJS.PJ,$CLJS.sI,$CLJS.QK,$CLJS.YH,$CLJS.nJ,$CLJS.iI,$CLJS.FJ,$CLJS.UJ,$CLJS.lI,$CLJS.hJ,$CLJS.qJ,$CLJS.YD,$CLJS.EI,$CLJS.CJ,$CLJS.tI,$CLJS.bK,$CLJS.DI,$CLJS.XH,$CLJS.$J,$CLJS.vK,$CLJS.fK,$CLJS.CI,$CLJS.RK,$CLJS.cK,$CLJS.KK,$CLJS.nI,$CLJS.$H,$CLJS.UK,$CLJS.YW,$CLJS.NJ,$CLJS.iD,$CLJS.AK,$CLJS.FK,$CLJS.XK,$CLJS.Ru,$CLJS.av],!0)),I3=null,J3=0,K3=0;;)if(K3<J3){var oqa=I3.$(null,K3);$CLJS.lV(oqa,o3);K3+=1}else{var L3=$CLJS.A(H3);
if(L3){var M3=L3;if($CLJS.re(M3)){var N3=$CLJS.$c(M3),pqa=$CLJS.ad(M3),qqa=N3,rqa=$CLJS.E(N3);H3=pqa;I3=qqa;J3=rqa}else{var sqa=$CLJS.C(M3);$CLJS.lV(sqa,o3);H3=$CLJS.D(M3);I3=null;J3=0}K3=0}else break}$CLJS.j3.o(null,n3,function(a){return k3(a)});$CLJS.j3.o(null,$CLJS.yG,function(a){return $CLJS.oh.j($CLJS.P,$CLJS.Mm.h(g3(),$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.j3.g(b)],null)})),a)});
$CLJS.j3.o(null,$CLJS.MP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);return $CLJS.oe(b)?(a=i3(b),c=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.MP,f3(q3,c)],null),$CLJS.m(a)?$CLJS.kf.h(c,a):c):a});$CLJS.j3.o(null,$CLJS.wG,function(a){return $CLJS.Qm.h($CLJS.j3,a)});
$CLJS.j3.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=null==b||$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[b,a],null):new $CLJS.S(null,2,5,$CLJS.T,[a,b],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,$CLJS.j3.g(a),i3(b)],null)});
$CLJS.j3.o(null,$CLJS.sl,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=i3($CLJS.PN(b,new $CLJS.k(null,3,[$CLJS.CG,$CLJS.sJ,$CLJS.Ll,$CLJS.BP,$CLJS.uL,$CLJS.fS],null)));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sl,a,b],null)});
$CLJS.j3.o(null,$CLJS.bZ,function(a){var b=h3(a);a=0==$CLJS.vP.g(a).lastIndexOf("__join",0)?$CLJS.Em.h(b,$CLJS.vP):b;return $CLJS.gn.l($CLJS.G([$CLJS.Ku($CLJS.Em.l(a,$CLJS.gZ,$CLJS.G([$CLJS.jZ])),$CLJS.j3),m3($CLJS.li(a,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.jZ],null)),$CLJS.jZ,$CLJS.$R),l3(a)]))});
$CLJS.j3.o(null,$CLJS.BZ,function(a){var b=q3;q3=$CLJS.oh.j($CLJS.P,$CLJS.Io(function(c,d){$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.HG);return new $CLJS.S(null,2,5,$CLJS.T,[d,c],null)}),$CLJS.MP.g(a));try{return $CLJS.Sb(function(c,d){return $CLJS.p_.j(c,d,$CLJS.j3)},m3($CLJS.p_.j($CLJS.p_.j(Upa(h3(a)),$CLJS.MP,function(c){return $CLJS.Qm.h(k3,c)}),$CLJS.OQ,function(c){return $CLJS.oh.h($CLJS.P,function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=
$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u),x=$CLJS.j3.g(v);v=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$_(v),$CLJS.F.h($CLJS.sl,$CLJS.C(x))?$CLJS.Zd(x):x],null);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);l=$CLJS.j3.g(q);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$_(q),$CLJS.F.h($CLJS.sl,$CLJS.C(l))?$CLJS.Zd(l):l],null),f($CLJS.zd(g)))}return null}},
null,null)}(c)}())}),$CLJS.MZ,$CLJS.IO),$CLJS.Fm.l(d3,$CLJS.MP,$CLJS.G([$CLJS.MZ,$CLJS.OQ])))}finally{q3=b}});$CLJS.j3.o(null,$CLJS.HZ,function(a){return $CLJS.Ku(h3(a),$CLJS.j3)});
$CLJS.j3.o(null,$CLJS.LZ,function(a){var b=h3(a),c=$CLJS.NO.g(b),d=l3(b),e=$CLJS.F.h($CLJS.uz.g($CLJS.$d($CLJS.gZ.g(a))),$CLJS.HZ)?$CLJS.TQ:$CLJS.uO;a=$CLJS.gn.l;b=$CLJS.Ku($CLJS.Em.l(b,$CLJS.gZ,$CLJS.G([$CLJS.NO])),$CLJS.j3);d=$CLJS.Pf([$CLJS.Xk,e,e,d]);c=$CLJS.A(c)?$CLJS.U.j(d,$CLJS.NO,c):d;return a.call($CLJS.gn,$CLJS.G([b,c]))});