var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Xoa,Q0,Yoa,R0,S0,k1,Zoa,$oa,o1,d1,q1,apa,f1;Xoa=function(a){return $CLJS.A(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};Q0=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Ie($CLJS.Bfa,a)?a:"_":$CLJS.m(b)?encodeURIComponent(a):a};
Yoa=function(a){var b=$CLJS.Qf(new $CLJS.k(null,1,[$CLJS.EG,!0],null)),c=$CLJS.M.h(b,$CLJS.Afa),d=$CLJS.M.h(b,$CLJS.EG);return $CLJS.A(a)?(b=$CLJS.cv.g(function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v);x=Q0(x,$CLJS.Gb(d));u.add(x);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}u=$CLJS.C(l);return $CLJS.nf(Q0(u,
$CLJS.Gb(d)),g($CLJS.zd(l)))}return null}},null,null)}(Xoa($CLJS.jG(a)))}()),$CLJS.m(c)?$CLJS.cv.g($CLJS.jg(c,b)):b):null};R0=function(a,b){if(null!=a&&null!=a.qh)a=a.qh(a,b);else{var c=R0[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=R0._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.table",a);}return a};
S0=function(a,b){if(null!=a&&null!=a.jh)a=a.jh(a,b);else{var c=S0[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=S0._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.card",a);}return a};$CLJS.T0=function(a,b){var c=0>b?$CLJS.E(a)+b:b;if(c>=$CLJS.E(a)||0>c)throw $CLJS.zj($CLJS.TH("Stage {0} does not exist",$CLJS.G([b])),new $CLJS.k(null,1,[$CLJS.Noa,$CLJS.E(a)],null));return c};
$CLJS.U0=function(a,b){a=$CLJS.d0(a);a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.gZ);return $CLJS.M.h($CLJS.Mg(a),$CLJS.T0(a,b))};$CLJS.V0=function(a,b,c,d){a=$CLJS.d0(a);a=$CLJS.Qf(a);var e=$CLJS.M.h(a,$CLJS.gZ);b=$CLJS.T0(e,b);c=$CLJS.R.R($CLJS.Rm,$CLJS.Mg(e),b,c,d);return $CLJS.U.j(a,$CLJS.gZ,c)};$CLJS.W0=function(a,b){return R0($CLJS.q0(a),b)};$CLJS.X0=function(a,b){return S0($CLJS.q0(a),b)};$CLJS.Z0=function(a,b,c){return $CLJS.Y0.j(a,b,c)};
k1=function(a,b,c){var d=$CLJS.Z0(a,b,c);return $CLJS.gn.l($CLJS.G([$CLJS.li(d,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,$CLJS.SI,$CLJS.Ll],null)),function(){var e=$CLJS.$0.D(a,b,c,$CLJS.a1);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.b1,e],null):null}(),function(){var e=$CLJS.Om.h($CLJS.lk,$CLJS.CG)(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.lk,e],null):null}(),function(){var e=$CLJS.j0.g(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.k0,$CLJS.c1.j(a,b,$CLJS.Ae(e)?$CLJS.W0(a,e):"string"===typeof e?
$CLJS.X0(a,$CLJS.e0(e)):null)],null):null}(),function(){var e=$CLJS.f0.g(d);return $CLJS.m(e)?new $CLJS.k(null,4,[d1,$CLJS.F.h(e,$CLJS.t0),$CLJS.e1,$CLJS.F.h(e,$CLJS.y0),f1,$CLJS.F.h(e,$CLJS.w0),$CLJS.g1,$CLJS.F.h(e,$CLJS.x0)],null):null}(),function(){var e=$CLJS.A0.g(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.h1,e],null):null}(),$CLJS.li(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.i1,$CLJS.j1],null))]))};$CLJS.l1=new $CLJS.N(null,"direction","direction",-633359395);
$CLJS.g1=new $CLJS.N(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.i1=new $CLJS.N(null,"breakout-position","breakout-position",-760153191);$CLJS.m1=new $CLJS.N(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.h1=new $CLJS.N(null,"selected","selected",574897764);Zoa=new $CLJS.N(null,"is-order-by-column","is-order-by-column",1320802549);
$oa=new $CLJS.w("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.n1=new $CLJS.N(null,"stage-number","stage-number",-1752729638);o1=new $CLJS.N("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.p1=new $CLJS.N(null,"include-joined?","include-joined?",-410632824);d1=new $CLJS.N(null,"is-from-previous-stage","is-from-previous-stage",956780376);
q1=new $CLJS.N(null,"is-source-table","is-source-table",1559075056);$CLJS.r1=new $CLJS.N(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.e1=new $CLJS.N(null,"is-from-join","is-from-join",-1107917905);$CLJS.s1=new $CLJS.N(null,"requires-column","requires-column",934105295);$CLJS.j1=new $CLJS.N(null,"order-by-position","order-by-position",-1307229997);apa=new $CLJS.N(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.b1=new $CLJS.N(null,"long-display-name","long-display-name",1550784464);
$CLJS.a1=new $CLJS.N(null,"long","long",-171452093);$CLJS.t1=new $CLJS.N(null,"short-name","short-name",-1767085022);$CLJS.u1=new $CLJS.N(null,"include-expressions?","include-expressions?",-1817639288);f1=new $CLJS.N(null,"is-calculated","is-calculated",-86687269);$CLJS.v1=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.CH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","display-name-method"),function(f,g,l){return $CLJS.BH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.w1=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.CH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","column-name-method"),function(f,g,l){return $CLJS.BH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.$0=function $0(a){switch(arguments.length){case 2:return $0.h(arguments[0],arguments[1]);case 3:return $0.j(arguments[0],arguments[1],arguments[2]);case 4:return $0.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.metadata.calculation.display_name",$CLJS.$0);$CLJS.$0.h=function(a,b){return $CLJS.$0.j(a,-1,b)};$CLJS.$0.j=function(a,b,c){return $CLJS.$0.D(a,b,c,$CLJS.Pj)};
$CLJS.$0.D=function(a,b,c,d){var e=$CLJS.SI.g($CLJS.DH(c));if($CLJS.m(e))return e;try{return $CLJS.v1.D(a,b,c,d)}catch(f){if(f instanceof Error)throw b=f,$CLJS.yj($CLJS.TH("Error calculating display name for {0}: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),$CLJS.RZ(b)])),new $CLJS.k(null,2,[$CLJS.uO,a,$CLJS.IG,c],null),b);throw f;}};$CLJS.$0.v=4;
$CLJS.x1=function x1(a){switch(arguments.length){case 2:return x1.h(arguments[0],arguments[1]);case 3:return x1.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.x1.h=function(a,b){return $CLJS.x1.j(a,-1,b)};
$CLJS.x1.j=function(a,b,c){var d=$CLJS.V.g($CLJS.DH(c));if($CLJS.m(d))return d;try{return $CLJS.w1.j(a,b,c)}catch(e){if(e instanceof Error)throw d=e,$CLJS.yj($CLJS.TH("Error calculating column name for {0}: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),$CLJS.RZ(d)])),new $CLJS.k(null,3,[$CLJS.IG,c,$CLJS.uO,a,$CLJS.n1,b],null),d);throw e;}};$CLJS.x1.v=3;
$CLJS.v1.o(null,$CLJS.Pj,function(a,b,c){a=$CLJS.XF($CLJS.XD);$CLJS.m($CLJS.dG("metabase.lib.metadata.calculation",a))&&$CLJS.eG("metabase.lib.metadata.calculation",a,$CLJS.X_("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),$oa,$CLJS.BH(c)])),null);return $CLJS.qe(c)&&$CLJS.C(c)instanceof $CLJS.N?$CLJS.ui($CLJS.C(c)):$CLJS.aj.l($CLJS.G([c]))});$CLJS.w1.o(null,$CLJS.Pj,function(a,b,c){return Yoa($CLJS.Nu($CLJS.$0.j(a,b,c),/[\(\)]/,""))});
$CLJS.y1=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.CH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,g,l){return l},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.z1=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.CH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","type-of-method"),function(f,g,l){return $CLJS.BH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.A1=function A1(a){switch(arguments.length){case 2:return A1.h(arguments[0],arguments[1]);case 3:return A1.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.A1.h=function(a,b){return $CLJS.A1.j(a,-1,b)};
$CLJS.A1.j=function(a,b,c){var d=$CLJS.DH(c);d=$CLJS.Qf(d);var e=$CLJS.M.h(d,$CLJS.PQ),f=$CLJS.lk.g(d);if($CLJS.m(f))return f;e=$CLJS.m($CLJS.m(e)?$CLJS.Ie($CLJS.UV,e):e)?$CLJS.jl:null;if($CLJS.m(e))return e;d=$CLJS.CG.g(d);if($CLJS.m(d))return d;a=$CLJS.z1.j(a,b,c);return $CLJS.NH(a,$CLJS.pl)?a:$CLJS.pl};$CLJS.A1.v=3;$CLJS.z1.o(null,$CLJS.Pj,function(a,b,c){return $CLJS.BL(c)});
$CLJS.z1.o(null,$CLJS.NL,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.A1.j(a,b,c)});$CLJS.z1.o(null,$CLJS.GX,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);return"string"===typeof d?$CLJS.BL(c):$CLJS.A1.j(a,b,d)});
$CLJS.Y0=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.CH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","metadata-method"),function(f,g,l){return $CLJS.BH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.Y0.o(null,$CLJS.Pj,function(a,b,c){try{return new $CLJS.k(null,4,[$CLJS.uz,$CLJS.gW,$CLJS.CG,$CLJS.A1.j(a,b,c),$CLJS.V,$CLJS.x1.j(a,b,c),$CLJS.SI,$CLJS.$0.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.yj($CLJS.TH("Error calculating metadata {0}",$CLJS.G([$CLJS.RZ(d)])),new $CLJS.k(null,3,[$CLJS.uO,a,$CLJS.n1,b,$CLJS.IG,c],null),d);}throw e;}});
$CLJS.B1=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.CH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","display-info-method"),function(f,g,l){return $CLJS.BH(l)},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.iL(o1,new $CLJS.S(null,19,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.SI,$CLJS.Pl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.b1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Pl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.k0,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,o1],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[d1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],
null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.e1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[f1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.g1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[q1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[apa,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Zoa,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.kk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Pl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oW,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Pl],null),
new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Pl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.t1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.Pl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.s1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.h1,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Pj,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.l1,
new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,$CLJS.EJ,$CLJS.rJ],null)],null)],null));$CLJS.c1=function c1(a){switch(arguments.length){case 2:return c1.h(arguments[0],arguments[1]);case 3:return c1.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.c1.h=function(a,b){return $CLJS.c1.j(a,-1,b)};
$CLJS.c1.j=function(a,b,c){try{return $CLJS.B1.j(a,b,c)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.yj($CLJS.TH("Error calculating display info for {0}: {1}",$CLJS.G([$CLJS.BH(c),$CLJS.RZ(d)])),new $CLJS.k(null,3,[$CLJS.uO,a,$CLJS.n1,b,$CLJS.IG,c],null),d);}throw e;}};$CLJS.c1.v=3;$CLJS.B1.o(null,$CLJS.Pj,function(a,b,c){return k1(a,b,c)});
$CLJS.B1.o(null,$CLJS.h0,function(a,b,c){return $CLJS.gn.l($CLJS.G([k1(a,b,c),new $CLJS.k(null,1,[q1,$CLJS.F.h($CLJS.YP.g($CLJS.C($CLJS.gZ.g(a))),$CLJS.Hk.g(c))],null)]))});
$CLJS.C1=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.CH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","visible-columns-method"),function(f,g,l){return $CLJS.BH(l)},$CLJS.Pj,e,a,b,c,d)}();$CLJS.C1.o(null,$CLJS.FG,function(){return $CLJS.Lg});module.exports={display_name:$CLJS.$0};