var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var g4,h4,i4,j4;g4=function(a,b,c){var d=$CLJS.U0(a,b);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.MP);d=$CLJS.uW($CLJS.Mm.j($CLJS.pi([c]),$CLJS.HG,$CLJS.Zd),d);if(!$CLJS.m(d))throw $CLJS.zj($CLJS.TH("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.k(null,3,[$CLJS.Ut,c,$CLJS.uO,a,$CLJS.n1,b],null));return d};h4=new $CLJS.N("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);
i4=new $CLJS.N("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);j4=new $CLJS.N("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);$CLJS.Nqa=new $CLJS.N(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.y1.o(null,$CLJS.MP,function(a,b){var c=$CLJS.Rf($CLJS.MP.g($CLJS.U0(a,b)));return $CLJS.m(c)?$CLJS.b2($CLJS.UH("and"),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.$0.D(a,b,v,$CLJS.a1);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);return $CLJS.nf($CLJS.$0.D(a,
b,q,$CLJS.a1),f($CLJS.zd(g)))}return null}},null,null)}(c)}()):null});$CLJS.Y0.o(null,$CLJS.MP,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.CG);e=$CLJS.M.h(e,$CLJS.lk);c=$CLJS.J(c,2,null);c=g4(a,b,c);return $CLJS.gn.l($CLJS.G([$CLJS.Z0(a,b,c),new $CLJS.k(null,2,[$CLJS.f0,$CLJS.F0,$CLJS.D1,$CLJS.HG.g($CLJS.Zd(c))],null),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.CG,d],null):null,$CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.lk,e],null):null]))});
$CLJS.v1.o(null,$CLJS.MP,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.$0.D(a,b,g4(a,b,c),d)});$CLJS.lV(i4,j4);
for(var k4=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kD,$CLJS.LJ],null)),l4=null,m4=0,n4=0;;)if(n4<m4){var Oqa=l4.$(null,n4);$CLJS.lV(Oqa,i4);n4+=1}else{var o4=$CLJS.A(k4);if(o4){var p4=o4;if($CLJS.re(p4)){var q4=$CLJS.$c(p4),Pqa=$CLJS.ad(p4),Qqa=q4,Rqa=$CLJS.E(q4);k4=Pqa;l4=Qqa;m4=Rqa}else{var Sqa=$CLJS.C(p4);$CLJS.lV(Sqa,i4);k4=$CLJS.D(p4);l4=null;m4=0}n4=0}else break}
$CLJS.v1.o(null,i4,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);if($CLJS.m(c))switch(a=$CLJS.$0.D(a,b,c,d),e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.TH("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.TH("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}else switch(e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.UH("Count");case "cum-count":return $CLJS.UH("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.w1.o(null,i4,function(a,b,c){a=$CLJS.J(c,0,null);a=a instanceof $CLJS.N?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}});$CLJS.Y0.o(null,i4,function(a,b,c){var d=$CLJS.U.j,e=$CLJS.tj($CLJS.Y0,j4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.U,a,$CLJS.Ll,$CLJS.RI)});$CLJS.v1.o(null,$CLJS.YI,function(){return $CLJS.UH("Case")});
$CLJS.w1.o(null,$CLJS.YI,function(){return"case"});$CLJS.lV(h4,j4);for(var r4=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.BJ,$CLJS.PK,$CLJS.LI,$CLJS.Zl,$CLJS.tK,$CLJS.qk,$CLJS.yK,$CLJS.rK,$CLJS.PI],null)),s4=null,t4=0,u4=0;;)if(u4<t4){var Tqa=s4.$(null,u4);$CLJS.lV(Tqa,h4);u4+=1}else{var v4=$CLJS.A(r4);if(v4){var w4=v4;if($CLJS.re(w4)){var x4=$CLJS.$c(w4),Uqa=$CLJS.ad(w4),Vqa=x4,Wqa=$CLJS.E(x4);r4=Uqa;s4=Vqa;t4=Wqa}else{var Xqa=$CLJS.C(w4);$CLJS.lV(Xqa,h4);r4=$CLJS.D(w4);s4=null;t4=0}u4=0}else break}
$CLJS.w1.o(null,h4,function(a,b,c){a=$CLJS.J(c,0,null);$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=a instanceof $CLJS.N?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.v1.o(null,h4,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.$0.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "avg":return $CLJS.TH("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.TH("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.TH("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.TH("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.TH("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.TH("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.TH("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.TH("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.TH("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.v1.o(null,$CLJS.lJ,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);return $CLJS.TH("{0}th percentile of {1}",$CLJS.G([c,$CLJS.$0.D(a,b,e,d)]))});$CLJS.w1.o(null,$CLJS.lJ,function(){return"percentile"});
$CLJS.lV($CLJS.lJ,j4);$CLJS.v1.o(null,$CLJS.MK,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return $CLJS.TH("Sum of {0} matching condition",$CLJS.G([$CLJS.$0.D(a,b,e,d)]))});$CLJS.w1.o(null,$CLJS.MK,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return["sum_where_",$CLJS.p.g($CLJS.x1.j(a,b,d))].join("")});$CLJS.lV($CLJS.MK,j4);$CLJS.v1.o(null,$CLJS.XJ,function(){return $CLJS.UH("Share of rows matching condition")});
$CLJS.w1.o(null,$CLJS.XJ,function(){return"share"});$CLJS.lV($CLJS.XJ,j4);$CLJS.v1.o(null,$CLJS.dK,function(){return $CLJS.UH("Count of rows matching condition")});$CLJS.w1.o(null,$CLJS.dK,function(){return"count-where"});$CLJS.lV($CLJS.dK,j4);
$CLJS.Y0.o(null,j4,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null),e=$CLJS.gn.l;d=$CLJS.m(d)?$CLJS.li($CLJS.Z0(a,b,d),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lR],null)):null;var f=$CLJS.tj($CLJS.Y0,$CLJS.Pj);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.gn,$CLJS.G([d,a]))});$CLJS.aW.o(null,$CLJS.MP,function(a){return a});$CLJS.v1.o(null,$CLJS.mW,function(a,b,c){a=$CLJS.Qf(c);a=$CLJS.M.h(a,$CLJS.pW);return $CLJS.SI.g(a.A?a.A():a.call(null))});
$CLJS.B1.o(null,$CLJS.mW,function(a,b,c){var d=$CLJS.Qf(c);a=$CLJS.M.h(d,$CLJS.AV);b=$CLJS.M.h(d,$CLJS.pW);c=$CLJS.M.h(d,$CLJS.nW);d=$CLJS.M.h(d,$CLJS.A0);a=$CLJS.U.l(b.A?b.A():b.call(null),$CLJS.t1,$CLJS.iG(a),$CLJS.G([$CLJS.s1,c]));return null!=d?$CLJS.U.j(a,$CLJS.h1,d):a});